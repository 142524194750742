<template>
  <header class="headerBox f-cb">
    <div class="header">
      <div class="w1280 f-cb">
        <router-link to="/" class="logo">
          <img height="100%" :src="globalConfig.aboveLogoImgUrl" />
        </router-link>
        <div class="tel">
          <br />
          <span>
            <a :href="'tel:' + globalConfig.hospitalPhoneNo">{{
              globalConfig.hospitalPhoneNo
            }}</a>
          </span>
        </div>
        <div class="searchBox">
          <!--id="topKey"-->
          <div>
            <input
              type="text"
              name="keyword"
              placeholder="请输入您想搜索的内容"
              class="text"
              v-model="searchData"
            />
            <button type="button" class="submit" @click="startSearch">
              搜索
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="nav">
      <div class="w1280x">
        <ul>
          <li :class="{ sub: true, on: $route.path === '/' }">
            <router-link to="/" class="tits">首页</router-link>
          </li>
          <!--第一种情况-->
          <li
            :class="{ sub: true, on: handleActive(item) }"
            v-for="item in topList"
            :key="item.id"
          >
            <router-link :to="item.templateDesc + '_' + item.id" class="tits">{{
              item.name
            }}</router-link>
            <div class="mnav">
              <div class="w1280 f-cb">
                <div class="title">
                  <router-link :to="item.templateDesc + '_' + item.id">{{
                    item.name
                  }}</router-link>
                </div>
                <!-- 医院概况 -->
                <div v-if="item.code == '8d967c6e196c4039ae4019e573a0e75e'">
                  <div class="navX f-cb">
                    <router-link
                      :to="child.templateDesc + '_' + child.id"
                      v-for="child in item.children"
                      :key="child.id"
                      >{{ child.name }}</router-link
                    >
                  </div>
                  <div class="con">
                    <div class="conNew">
                      <div class="titsX">
                        <a href="javascript:;">{{ item.name }}</a>
                      </div>
                      <div class="titX">{{ item.contextStrs }}</div>
                      <router-link
                        :to="item.templateDesc + '_' + item.id"
                        class="more"
                        >more</router-link
                      >
                    </div>
                  </div>
                  <!-- 暂时没改 -->
                  <div class="conRight">
                    <router-link to="/formAppoint_9">
                      <div class="icon">
                        <img
                          src="https://www.sclsnk.com/file/skin/picture/nav_icon1.png"
                        />
                      </div>
                      <p>自助挂号</p>
                    </router-link>
                    <router-link to="/reportSearch_115">
                      <div class="icon">
                        <img
                          src="https://www.sclsnk.com/file/skin/picture/nav_icon6.png"
                        />
                      </div>
                      <p>报告查询</p>
                    </router-link>
                    <a :href="globalConfig.zixun">
                      <div class="icon">
                        <img
                          src="https://www.sclsnk.com/file/skin/picture/nav_icon8.png"
                        />
                      </div>
                      <p>在线咨询</p>
                    </a>
                  </div>
                </div>
                <!-- 学科介绍 -->
                <div
                  class="subjectNav"
                  v-if="item.code == '62a6c93d2a484f4cade45d9cdd28cd30'"
                >
                  <dl v-for="item in subjectList" :key="item.id">
                    <dt>{{ item.officeName }}</dt>
                    <dd>
                      <router-link
                        :to="'/subjectlist_3?type=' + child.id"
                        v-for="child in item.children"
                        :key="child.id"
                        >{{ child.officeName }}</router-link
                      >
                    </dd>
                  </dl>
                </div>
                <!-- 医院动态 -->
                <div v-if="item.code == '43acf6ac7cb14a1297402f8b4611ed1d'">
                  <div class="navX f-cb">
                    <router-link
                      :to="child.templateDesc + '_' + child.id"
                      v-for="child in item.children"
                      :key="child.id"
                      >{{ child.name }}</router-link
                    >
                  </div>
                  <div class="con">
                    <div class="conNew">
                      <div class="titsX">
                        <a href="javascript:;">{{ item.name }}</a>
                      </div>
                      <dl>
                        <dd v-for="val in newList" :key="val.id">
                          <router-link
                            :to="
                              val.templateDesc + '_' + val.dictId + '/' + val.id
                            "
                            >{{ val.title }}</router-link
                          >
                        </dd>
                      </dl>
                    </div>
                  </div>
                  <!-- 暂时没改 -->
                  <div class="conRight">
                    <router-link to="/formAppoint_9">
                      <div class="icon">
                        <img
                          src="https://www.sclsnk.com/file/skin/picture/nav_icon1.png"
                        />
                      </div>
                      <p>自助挂号</p>
                    </router-link>
                    <router-link to="/reportSearch_115">
                      <div class="icon">
                        <img
                          src="https://www.sclsnk.com/file/skin/picture/nav_icon6.png"
                        />
                      </div>
                      <p>报告查询</p>
                    </router-link>
                    <a :href="globalConfig.zixun">
                      <div class="icon">
                        <img
                          src="https://www.sclsnk.com/file/skin/picture/nav_icon8.png"
                        />
                      </div>
                      <p>在线咨询</p>
                    </a>
                  </div>
                </div>
                <!-- 党建文化 -->
                <div v-if="item.code == '1c2d824cef214f9587bb9891868da38f'">
                  <div class="navX f-cb">
                    <router-link
                      :to="child.templateDesc + '_' + child.id"
                      v-for="child in item.children"
                      :key="child.id"
                      >{{ child.name }}</router-link
                    >
                  </div>
                  <div class="con">
                    <div class="conNew">
                      <div class="titsX">
                        <a href="javascript:;">{{ item.name }}</a>
                      </div>
                      <dl>
                        <dd v-for="val in partyList" :key="val.id">
                          <router-link
                            :to="
                              val.templateDesc + '_' + val.dictId + '/' + val.id
                            "
                            >{{ val.title }}</router-link
                          >
                        </dd>
                      </dl>
                    </div>
                  </div>
                  <!-- 暂时没改 -->
                  <div class="conRight">
                    <router-link to="/formAppoint_9">
                      <div class="icon">
                        <img
                          src="https://www.sclsnk.com/file/skin/picture/nav_icon1.png"
                        />
                      </div>
                      <p>自助挂号</p>
                    </router-link>
                    <router-link to="/reportSearch_115">
                      <div class="icon">
                        <img
                          src="https://www.sclsnk.com/file/skin/picture/nav_icon6.png"
                        />
                      </div>
                      <p>报告查询</p>
                    </router-link>
                    <a :href="globalConfig.zixun">
                      <div class="icon">
                        <img
                          src="https://www.sclsnk.com/file/skin/picture/nav_icon8.png"
                        />
                      </div>
                      <p>在线咨询</p>
                    </a>
                  </div>
                </div>
                <!-- 联系我们 -->
                <div v-if="item.code == '39964bd2fcc243f0a0f6091e44c0ab14'">
                  <div class="navX f-cb">
                    <div
                      class="a"
                      v-for="child in item.children"
                      :key="child.id"
                    >
                      <router-link
                        :to="child.templateDesc + '_' + child.id"
                        v-if="child.jumpType == 0"
                        >{{ child.name }}</router-link
                      >
                      <a :href="child.bannerUrl" v-else>{{ child.name }}</a>
                    </div>
                  </div>
                  <div class="con">
                    <div class="conNew">
                      <div class="titsX">
                        <a href="javascript:;">{{ item.name }}</a>
                      </div>
                      <dl>
                        <dd>
                          <router-link
                            :to="item.templateDesc + '_' + item.id"
                            >{{ item.name }}</router-link
                          >
                        </dd>
                      </dl>
                    </div>
                  </div>
                  <!-- 暂时没改 -->
                  <div class="conRight">
                    <router-link to="/formAppoint_9">
                      <div class="icon">
                        <img
                          src="https://www.sclsnk.com/file/skin/picture/nav_icon1.png"
                        />
                      </div>
                      <p>自助挂号</p>
                    </router-link>
                    <router-link to="/reportSearch_115">
                      <div class="icon">
                        <img
                          src="https://www.sclsnk.com/file/skin/picture/nav_icon6.png"
                        />
                      </div>
                      <p>报告查询</p>
                    </router-link>
                    <a :href="globalConfig.zixun">
                      <div class="icon">
                        <img
                          src="https://www.sclsnk.com/file/skin/picture/nav_icon8.png"
                        />
                      </div>
                      <p>在线咨询</p>
                    </a>
                  </div>
                </div>
                <!-- 专家团队 -->
                <div v-if="item.code == '5ff05b2ce335491299d511d89f64b76c'">
                  <div class="navX f-cb">
                    <router-link
                      to="/teamlist_4"
                      v-for="subject in subjectList"
                      :key="subject.id"
                      >{{ subject.officeName }}</router-link
                    >
                  </div>
                  <div class="con">
                    <div class="conNew">
                      <div class="titsX">
                        <a href="javascript:;">{{ item.name }}</a>
                      </div>
                      <ul
                        style="list-style: none; display: flex; flex-wrap: wrap"
                      >
                        <li
                          style="margin-left: 15px; display: inline"
                          v-for="item in proList"
                          :key="item.id"
                        >
                          <router-link
                            style="color: white"
                            :to="'/teamlist_4/' + item.id"
                            >{{ item.name }}</router-link
                          >
                        </li>
                      </ul>
                    </div>
                  </div>
                  <!-- 暂时没改 -->
                  <div class="conRight">
                    <router-link to="/formAppoint_9">
                      <div class="icon">
                        <img
                          src="https://www.sclsnk.com/file/skin/picture/nav_icon1.png"
                        />
                      </div>
                      <p>自助挂号</p>
                    </router-link>
                    <router-link to="/reportSearch_115">
                      <div class="icon">
                        <img
                          src="https://www.sclsnk.com/file/skin/picture/nav_icon6.png"
                        />
                      </div>
                      <p>报告查询</p>
                    </router-link>
                    <a :href="globalConfig.zixun">
                      <div class="icon">
                        <img
                          src="https://www.sclsnk.com/file/skin/picture/nav_icon8.png"
                        />
                      </div>
                      <p>在线咨询</p>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </header>
</template>

<script>
import { Store } from "@/store/index.js";
import { mapState } from "pinia";
export default {
  data() {
    return {
      show: false,
      searchData: "",
      path: "",
      businessCode: "", // 业务介绍 code 用于特殊处理
      navShow: false,
      subShow: false,
      proList: [],
      newList: [],
      partyList: [],
    };
  },
  computed: {
    ...mapState(Store, ["navList", "subjectList", "globalConfig"]),
    topList() {
      return this.navList.slice(0, 7);
    },
    handleActive() {
      return (val) => {
        return this.$route.path == val.templateDesc + "_" + val.id;
      };
    },
  },
  created() {
    this.getNews();
    const params = {
      pageIndex: 1,
      pageSize: 16,
    };
    this.$api.getDoctorList(params).then((res) => {
      this.proList = res.data.list;
    });
  },
  mounted() {
    window.onscroll = function () {
      const nav = document.getElementsByClassName("nav")[0];
      if (window.innerWidth > 1024) {
        if (document.documentElement.scrollTop > 101) {
          nav.classList.add("on");
        } else {
          nav.classList.remove("on");
        }
      }
    };
  },
  methods: {
    changeNav(val, parent) {
      // 提取参数
      const { id, children } = val;
      window.scrollTo(0, 0);
      if (id == this.navList[0].id) {
        // 首页
        this.$router.push("/");
      } else {
        if (parent) {
          // 点击二级字典 直接跳转
          this.$router.push(val.templateName + "_" + parent.id + "/" + val.id);
        } else {
          // 点击一级字典
          const defaultRoute = children[0];
          if (defaultRoute) {
            // 有子级，默认第一个
            this.$router.push(
              defaultRoute.templateName + "_" + val.id + "/" + defaultRoute.id
            );
          } else {
            // 无子级，直接跳转
            this.$router.push(val.templateName + "_" + val.id);
          }
        }
      }

      this.navShow = false;
    },
    startSearch() {
      this.$router.push("/search?keyword=" + this.searchData);
    },
    getNews() {
      const codes = {
        newList: "d6322e9f491845638abd07b6bab593a8",
        partyList: "c8d87756da784bca9fd0fa2a7850fd68",
      };
      for (const key in codes) {
        const ele = codes[key];
        const params = {
          pageIndex: 1,
          pageSize: 4,
          code: ele,
        };
        this.$api.getNewsList(params).then((res) => {
          this[key] = res.data.list;
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@keyframes heightIn {
  from {
    max-height: 0;
  }
  to {
    max-height: 500px;
  }
}
.mnav {
  animation: heightIn 0.5s;
  overflow: hidden;
}
.sub:hover {
  .mnav {
    display: block !important;
  }
}
.main .headerBox .nav ul li .mnav .navX .a a {
  width: 100%;
}
.a {
  width: 50%;
  float: left;
  font-size: 15px;
  line-height: 36px;
  color: #fff;
  display: block;
  box-sizing: border-box;
  text-align: left;
}
</style>