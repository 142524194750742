<template>
  <footer class="footerBox">
    <div class="bottom">
      <div class="aboutTitle">便捷就医</div>
      <div class="list">
        <ul class="f-cb">
          <li v-for="(item, idx) in quicklyNav" :key="item.name">
            <router-link :to="item.path">
              <div class="icon">
                <img
                  :src="
                    'https://www.sclsnk.com/file/mskin/picture/index_icon' +
                    (idx + 1) +
                    '.png'
                  "
                />
              </div>
              <p>{{ item.name }}</p>
            </router-link>
          </li>
          <li>
            <a :href="globalConfig.zixunm">
              <div class="icon">
                <img
                  src="https://www.sclsnk.com/file/mskin/picture/bottom_icon6.png"
                />
              </div>
              <p>在线咨询</p>
            </a>
          </li>
        </ul>
      </div>
      <div class="mainLine"></div>
    </div>
    <div class="footer">
      <div class="logo">
        <img width="100%" :src="globalConfig.logoImgUrl" />
      </div>
      <div class="con">
        <div class="item f-cb">
          <div class="tits">联系我们</div>
          <div class="tit">
            <div class="t f-cb">
              <span>医院电话</span>
              <em></em>
              <i>{{ globalConfig.hospitalPhoneNo }}</i>
            </div>
            <div class="t f-cb">
              <span>门诊时间</span>
              <em></em>
              <i>08：00-17：00（节假日不休）</i>
            </div>
            <div class="t f-cb">
              <span>急诊时间</span>
              <em></em>
              <i>00：00-24：00（节假日不休）</i>
            </div>
            <div class="t f-cb">
              <span>咨询时间</span>
              <em></em>
              <i>7：00-22：30（节假日不休）</i>
            </div>
          </div>
        </div>
        <div class="item f-cb">
          <div class="tits">交通路线</div>
          <div class="tit">
            <div class="t f-cb">
              <span>医院地址</span>
              <em></em>
              <i>{{ globalConfig.companyAddress }}</i>
            </div>
            <div class="t f-cb">
              <span>地铁线路</span>
              <em></em>
              <i>{{ globalConfig.subwayLine }}</i>
            </div>
            <div class="t f-cb">
              <span>公交线路</span>
              <em></em>
              <i>{{ globalConfig.transportLine }}</i>
            </div>
            <div class="t f-cb">
              <span>公交线路</span>
              <em></em>
              <i>{{ globalConfig.transportLine2 }}</i>
            </div>
          </div>
        </div>
      </div>
      <div class="foot">
        <div class="tit">
          <div class="t f-cb">
            <span>备案编号</span>
            <em></em>
            <i>
              <a
                href="https://beian.miit.gov.cn/"
                target="_self"
                style="color: #fff"
                >{{ globalConfig.icpMsg }}</a
              >
            </i>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
import { Store } from "@/store/index.js";
import { mapState } from "pinia";
import { URL } from "@/util/config.js";
export default {
  data() {
    return {
      URL: URL,
      quicklyNav: [
        { name: "自助挂号", path: "/formAppoint_9" },
        { name: "报告查询", path: "/reportSearch_115" },
        { name: "就医流程", path: "/procedure_114" },
      ],
      navList: [
        { name: "医院概况", link: "", subs: [] },
        { name: "学科介绍", link: "", subs: [] },
        { name: "专家团队", link: "", subs: [] },
        { name: "健康科普", link: "", subs: [] },
        { name: "医院动态", link: "", subs: [] },
        { name: "党建文化", link: "", subs: [] },
        { name: "联系我们", link: "", subs: [] },
      ],
    };
  },
  computed: {
    ...mapState(Store, ["globalConfig"]),
  },
  mounted() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.footer {
  width: 100%;
}
</style>
