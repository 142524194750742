<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {};
  },
  created() {
    this.changeFont(750, 1300);

    this.$store.setNavList();
    this.$store.setConfig();
    this.$store.setSubject();
  },
  methods: {
    /**
     * 适配函数 1rem == clientWidth / 7.5 + px
     * designWidth 基准尺寸
     * maxWidth 最大尺寸
     */
    changeFont(designWidth, maxWidth) {
      let doc = document,
        win = window;
      let docEl = doc.documentElement;
      let remDom = document.getElementsByTagName("html")[0];
      //获取基准字体大小
      function refreshRem() {
        // let width = parseInt(window.screen.width); // uc有bug
        let width = docEl.getBoundingClientRect().width;
        if (!maxWidth) {
          maxWidth = 1366;
        }
        if (width > maxWidth) {
          width = maxWidth;
        }
        let rem = (width / designWidth) * 100;
        //得到的rem基准字体大小，这里乘以100是为了适配有的浏览器不识别小数，会导致设置字体无效。
        //设置根元素html的字体大小为基准字体大小，在css中每rem就是这个值的大小。
        remDom.style.fontSize = rem + "px";
      }
      refreshRem();
      /* 以下为在不同的时机重新计算rem*/
      win.addEventListener(
        "resize",
        function () {
          let width = docEl.getBoundingClientRect().width;
          if (width <= 1000) {
            refreshRem();
          }
        },
        false
      );

      win.addEventListener(
        "pageshow",
        function (e) {
          if (e.persisted) {
            // 浏览器后退的时候重新计算
            let width = docEl.getBoundingClientRect().width;
            if (width <= 1000) {
              refreshRem();
            }
          }
        },
        false
      );
    },
  },
};
</script>

<style lang="scss">
@import url("./assets/css/style.css");
@import url("./assets/css/reset.css");
* {
  margin: 0;
  padding: 0;
}
::-webkit-scrollbar {
  width: 8px;
  height: 4px;
}
::-webkit-scrollbar-thumb {
  background-color: rgba($color: #000000, $alpha: 0.2);
  border-radius: 10px;
  -webkit-box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1);
}
::-webkit-scrollbar-thumb:hover {
  background-color: rgba($color: #000000, $alpha: 0.4);
  -webkit-box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1);
}
:hover:-webkit-scrollbar-thumb {
  background-color: rgba($color: #000000, $alpha: 0.2);
  border-radius: 10px;
  -webkit-box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1);
}
::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: transparent;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
}
::-webkit-scrollbar-track:hover {
  background-color: rgba($color: #000000, $alpha: 0.01);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.4);
}
body {
  font-size: 14px;
}
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
}
.el-pagination {
  text-align: center;
  margin-top: 20px;
}
.el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li {
  background-color: #fff;
}
.loading {
  width: 100vw;
  height: 100vh;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.view-box {
  position: relative;
}
a {
  text-decoration: none;
}
.content,
.desc,
.pangod-classify-html {
  img,
  table,
  video {
    max-width: 100%;
    height: auto;
  }
  p {
    margin: 10px 0;
  }
}
@media (max-width: 1300px) {
  ::-webkit-scrollbar {
    width: 3px;
  }
}
@media (max-width: 750px) {
  ::-webkit-scrollbar {
    height: 0;
  }
}
</style>
